<template>
  <div style="margin:200px auto;width:300px">
    <el-button type="primary" @click="exportShopFun">门店下载</el-button>
    <el-button type="primary" @click="exportRegistrationFun">礼堂下载</el-button>
  </div>
</template>
<script>
  import { exportShop, exportRegistration, cookList } from "@/api/login";
  export default {
    mounted() {
      this.systemSeder()
    },
    data() {
    },
    methods: {
      systemSeder() {
        cookList('', '', '').then(() => {

        })
      },

      exportShopFun() {
        exportShop().then(res => {
          console.log(res)
          // if (res.size < 100) {
          //   Message({ message: '没有权限，请联系管理员授权', type: 'error' })
          //   return
          // }
          const blob = new Blob([res], { type: 'application/vnd.ms-excel' });
          var elink = document.createElement('a');
          elink.download = '门店列表.xlsx';
          elink.style.display = 'none';
          console.log(blob)
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          document.body.removeChild(elink);
        })
      },
      exportRegistrationFun() {
        exportRegistration().then(res => {
          // if (res.size < 100) {
          //   Message({ message: '没有权限，请联系管理员授权', type: 'error' })
          //   return
          // }
          const blob = new Blob([res], { type: 'application/vnd.ms-excel' });
          var elink = document.createElement('a');
          elink.download = '礼堂列表.xlsx';
          elink.style.display = 'none';
          console.log(blob)
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          document.body.removeChild(elink);
        })
      }
    }
  }
</script>